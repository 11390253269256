<template>
  <div class="card-grid">
    <!-- AUTON KÄYTTÖVOIMA -->
    <ChartCard chartType="pie" questionType="public_transport_tickets" />
    <!-- AUTON KÄYTTÖVOIMA END -->
    <ChartCard
      chartType="bar-horizontal"
      questionType="public_transport_tickets"
      full
    />
    <ChartCard chartType="bar-horizontal" questionType="work_parking" full />
    <ChartCard chartType="bar-horizontal" questionType="parking_payer" full />
    <ChartCard
      chartType="bar-horizontal"
      questionType="worktrip_satisfaction"
      full
    />
    <ChartCard chartType="text" questionType="satisfaction_reasoning" full />
    <ChartCard chartType="bar" questionType="offer_sustainable_travelling" />
    <ChartCard chartType="bar" questionType="support_sustainable_travelling" />
    <ChartCard
      chartType="text"
      questionType="how_to_support_sustainable_travelling"
      full
    />
  </div>
</template>
<script>
import ChartCard from "../chart-cards/ChartCard.vue";
export default {
  metaInfo() {
    return {
      title: this.$t("dashboard.nav.commuting"),
    };
  },
  components: { ChartCard },
};
</script>
